import axios from '@/config/httpConfig'

// 保存标签
export function GetTagSaveTag(data) {
  return axios.post('/tag/saveTag', data)
}

// 给记录添加标签
export function GetTagAddTagRelation(data) {
  return axios.post('/tag/addTagRelation', data)
}
// 获取视图详细信息
export function GetViewInfo(data) {
  return axios.post('/view/getViewInfo', data)
}
// 删除标签
export function GetTagDeleteTag(data) {
  return axios.post('/tag/deleteTag', data)
}

// 删除记录上的标签
export function GetTagDeleteTagRelationg(data) {
  return axios.post('/tag/deleteTagRelation', data)
}

// 根据标签id获取标签
export function GetTagGetTagById(data) {
  return axios.post('/tag/getTagById', data)
}

// 根据记录id获取标签
export function GetTagGetTagsByRecordId(data) {
  return axios.post('/tag/getTagsByRecordId', data)
}

// 搜索标签
export function GetTagSearchTags(data) {
  return axios.post('/tag/searchTags', data)
}

// 查询我的标签
export function GetTagGetMyAllTagsg(data) {
  return axios.post('/tag/getMyAllTags', data)
}

// 获取管理标签权限
export function GetTagGetManageTagPermission() {
  return axios.post('/tag/getManageTagPermission')
}
