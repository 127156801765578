<!-- 对象通用列表页 -->
<template>
  <div style="height: 100%">
    <el-card class="box-card tab_box" style="height: 100%">
      <div style="width: 100%">
        <div class="pull-left topContent">
          <h3 style="margin-left: 15px; margin-top: 15px; margin-bottom: 5px">
            <router-link to
              ><i
                class="el-icon-arrow-left icon"
                style="color: #ccc"
                @click="$router.back(-1)"
              ></i>
            </router-link>
            <!-- 管理标签-潜在客户 -->
            {{ $t("label.tag.manage.title", { tabName: tabName }) }}
          </h3>
          <div class="pull-left left-content">
            <!-- 列表表头说明文字 -->
            <!-- 与潜在客户相关联的所有标签如下，您可以对标签进行重命名或删除 -->
            {{ $t("label.tag.manage.description", { tabName: tabName }) }}
          </div>
          <el-button type="primary" size="mini" class="button" @click="news">
            <!-- 新增标签 -->
            {{ $t("label.tag.manage.newtag") }}
          </el-button>
        </div>
      </div>

      <!-- 列表 -->
      <div>
        <el-table
          :data="dataList"
          border
          style="width: 100%; overflow: hidden"
          :height="tableheight"
          highlight-current-row
          :empty-text="$t('label.dashboard.noRecord')"
        >
          <!-- 标签 -->
          <el-table-column
            prop="name"
            :label="$t('label.label')"
            class="biaoqiao"
          >
            <template slot-scope="scope">
              <i
                class="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
                style="
                  margin-left: 35px;
                  margin-right: 30px;
                  cursor: pointer;
                  float: left;
                  margin-top: 10px;
                "
              ></i>
              <i
                class="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
                style="cursor: pointer; float: left; margin-top: 10px"
              ></i>
              <el-tag
                :style="{
                  background:
                    scope.row.color === 'hong'
                      ? hong
                      : scope.row.color === 'huang'
                      ? huang
                      : scope.row.color === 'lan'
                      ? lan
                      : scope.row.color === 'hui'
                      ? hui
                      : scope.row.color === 'lv'
                      ? lv
                      : '',
                  color: color,
                }"
              >
                {{ scope.row.name }}</el-tag
              >
            </template>
          </el-table-column>
          <!-- 记录数 -->
          <el-table-column prop="tagnum" :label="$t('label.tag.manage.record')">
          </el-table-column>
          <!-- 创建人 -->
          <el-table-column
            prop="createbyidccname"
            :label="$t('label.createby')"
          >
          </el-table-column>
          <!-- 最后修改人 -->
          <el-table-column
            prop="lastmodifybyidccname"
            :label="$t('label.lastmodifyby')"
          >
          </el-table-column>
        </el-table>
      </div>

      <!-- 新增标签 -->
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :before-close="close"
      >
        <!-- 请输入内容 -->
        <el-input
          v-model="input"
          :placeholder="$t('label_tabpage_contentz')"
        ></el-input>
        <div style="margin-left: -10px; margin-top: 20px">
          <!-- 可见性 -->
          <span class="all"> {{ $t("label.customsetting.visible") }}</span>
          <!-- 公用 -->
          <el-radio v-model="radio" label="public" class="radio">
            {{ $t("label.customsetting.visible.public") }}</el-radio
          >
          <!-- 私用 -->
          <el-radio v-model="radio" label="private" class="radio">
            {{ $t("label.tag.addtags.private") }}</el-radio
          >
        </div>
        <div style="margin-top: 17px; margin-left: 10px">
          <!-- 标签颜色 -->
          <span class="tagColor"> {{ $t("label.tag.addtags.tagcolour") }}</span>
          <div class="colors">
            <el-radio
              v-model="types"
              label="hong"
              class="hong"
              :disabled="isReadyonly == 1"
            ></el-radio>
            <el-radio
              v-model="types"
              label="huang"
              class="huang"
              :disabled="isReadyonly == 1"
            ></el-radio>
            <el-radio
              v-model="types"
              label="lv"
              class="lv"
              :disabled="isReadyonly == 1"
            ></el-radio>
            <el-radio
              v-model="types"
              label="lan"
              class="lan"
              :disabled="isReadyonly == 1"
            ></el-radio>
            <el-radio
              v-model="types"
              label="hui"
              class="hui"
              :disabled="isReadyonly == 2"
            ></el-radio>
          </div>
          <div style="clear: both"></div>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 确定 -->
          <el-button @click="add" size="mini" class="add_color">
            {{ $t("label.ems.confirm") }}</el-button
          >
          <!-- 取消 -->
          <el-button @click="close" size="mini">
            {{ $t("button_partner_cancel") }}</el-button
          >
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {
  GetTagSaveTag,
  GetTagGetTagById,
  GetTagDeleteTag,
  GetTagGetMyAllTagsg,
  GetTagGetManageTagPermission,
} from "./api";

export default {
  data() {
    return {
      isReadyonly: 1,
      title: this.$i18n.t("label.tag.manage.newtag"),
      isNew: true,
      color: "#fff",
      hong: "#F6817B",
      huang: "#FEC366",
      lan: "#62A5F3",
      hui: "#C8C6C6",
      lv: "#7cedcc",
      objId: "",
      input: "",
      radio: "",
      types: "hong",
      dialogVisible: false, //新建框显示与隐藏,
      dataList: [], //标签表格数据
      Single: [], //编辑数据
      isCreatePrivateTag: "", //创建私有标签权限
      isManageTag: "", //创建公有标签权限
      tabName: "",
      tableheight: `${document.body.offsetHeight - 185}px`,
      editId: "",
    };
  },
  created() {
    this.objId = this.$route.query.objId;
    this.tabName = this.$route.query.tabName;
    this.manage();
    this.GetTagGetMyAllTagsgs();
    window.addEventListener("resize", this.tabheight);
  },
  beforeDestroy() {
    // 移除绑定的listenResizeFn事件监听
    window.removeEventListener("resize", this.tabheight);
  },
  methods: {
    tabheight() {
      this.tableheight = document.body.offsetHeight - 185 + "px";
    },
    //权限管理
    async manage() {
      let res = await GetTagGetManageTagPermission();
      this.isManageTag = res.data.isManageTag;
      this.isCreatePrivateTag = res.data.isCreatePrivateTag;
      // 根据权限设置默认值
      if (this.isManageTag === "false") {
        this.radio = "private";
      } else if (this.isCreatePrivateTag === "false") {
        this.radio = "public";
      }
    },
    //查询所有标签
    async GetTagGetMyAllTagsgs() {
      let params = {
        id: this.objId,
      };
      let res = await GetTagGetMyAllTagsg(params);
      this.dataList = res.data;
    },
    async handleEdit(index, row = {}) {
      // 需要判断标签类型和当前的管理权限
      if (
        (row.type == "private" && this.isCreatePrivateTag === "true") ||
        (row.type == "public" && this.isManageTag === "true")
      ) {
        this.editId = row.id;
        if (this.isNew) {
          this.title = this.$i18n.t("label.tag.edittags");
          this.dialogVisible = true;
          let params = {
            objId: this.objId,
            id: row.id,
          };
          let res = await GetTagGetTagById(params);
          if (res.result) {
            this.Single = res.data[0];
            this.input = res.data[0].name;
            this.radio = res.data[0].type;
            this.types = res.data[0].color;
          }
          this.isNew = false;
        }
      } else {
        // 无权限提示，权限不足
        this.$message.info(this.$i18n.t("message.nopermession"));
      }
    },
    handleDelete(index, row = {}) {
      // 需要判断标签类型和当前的管理权限
      if (
        (row.type == "private" && this.isCreatePrivateTag === "true") ||
        (row.type == "public" && this.isManageTag === "true")
      ) {
        let params = {
          objId: this.objId,
          id: row.id,
        };
        this.$confirm(this.$i18n.t("label.weixin.confirm.delete"), {
          confirmButtonText: this.$i18n.t("label.ems.confirm"),
          cancelButtonText: this.$i18n.t("button_partner_cancel"),
          type: "warning",
        })
          .then(async () => {
            let res = await GetTagDeleteTag(params);
            if (res.result) {
              this.GetTagGetMyAllTagsgs();
            }
            this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
          })
          .catch(() => {
            this.$message.info(this.$i18n.t("label.delete.cancel"));
          });
      } else {
        // 无权限提示，权限不足
        this.$message.info(this.$i18n.t("message.nopermession"));
      }
    },
    //保存标签
    async add() {
      let params = {
        id: this.editId ? this.editId : "",
        objId: this.objId,
        name: this.input,
        color: this.types,
        type: this.radio,
      };
      let res = await GetTagSaveTag(params);
      if (res.result) {
        this.dialogVisible = false;

        this.GetTagGetMyAllTagsgs();
        this.title = this.$i18n.t("label.tag.manage.newtag");
        this.isNew = true;
        this.input = "";
        this.radio = "";
        this.types = "hong";
      }
    },
    //新增
    news() {
      this.editId = "";
      this.dialogVisible = true;
      this.input = "";
      if(this.isManageTag === "true" && this.isCreatePrivateTag === "true"){
        // 有管理员和用户权限时，默认公有类型
        this.radio = "public";
      } else if(this.isManageTag === "true"){
        // 只有管理员权限时，默认公有类型
        this.radio = "public";
      } else if(this.isCreatePrivateTag === "true"){
        // 只有用户权限时，默认私有类型
        this.radio = "private";
      }
      this.types = "hong";
    },
    //取消
    close() {
      if (this.isNew) {
        this.dialogVisible = false;
        this.isNew = true;
      } else {
        this.isNew = true;
        this.dialogVisible = false;
        setTimeout(() => {
          this.title = this.$i18n.t("label.tag.manage.newtag");
        }, 200);
      }
    },
  },
  watch: {
    radio: {
      handler: function (value) {
        if (
          this.isManageTag === "false" &&
          this.isCreatePrivateTag === "false"
        ) {
          this.$message.info(this.$i18n.t("label.weixin.donot.permission"));
          this.input = "";
          this.isNewTag = false;
          return;
        }
        if (value === "public") {
          if (this.isManageTag === "false") {
            // 对不起，您没有权限创建公用标签
            this.$message.info(this.$i18n.t("message.nopermession"));
            this.radio = "private";
            this.isReadyonly = 2;
            this.types = "hong";
            return;
          }
          this.isReadyonly = 1;
          this.types = "hui";
        } else if (value === "private") {
          if (this.isCreatePrivateTag === "false") {
            // 对不起，您没有权限创建私用标签
            this.$message.info(this.$i18n.t("message.nopermession"));
            this.radio = "public";
            this.isReadyonly = 1;
            this.types = "hui";
            return;
          }
          this.isReadyonly = 2;
          this.types = "hong";
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-card__body {
  padding: 0;
}
.icon {
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.screen {
  position: absolute;
  right: 35px;
  top: 201px;
  z-index: 99;
}

.LabelView {
  position: absolute;
  right: 87px;
  top: 200px;
  z-index: 99;
}

.topContent {
  width: 100%;
  height: 100px;
  background: #fff;
  margin-top: -24px;
  padding-top: 20px;
}

.left-content {
  height: 14px;
  font-size: 14px;

  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 21px;
  margin-left: 15px;

  .choose-view {
    width: 65%;
    margin-bottom: 10px;
  }
}

.button {
  float: right;
  margin-right: 15px;
  margin-top: -15px;
  // width: 70px;
  // height: 30px;
  // text-align: center;
  // background: rgba(0, 109, 204, 1);

  // ::v-deep span {
  //   width: 65px;
  //   height: 12px;
  //   font-size: 12px;
  //   font-weight: 500;
  //   color: rgba(255, 255, 255, 1);
  //   line-height: 18px;
  //   position: relative;
  //   left: -9px;
  //   top: -7px;
  // }
}

.right-content {
  width: 50%;
  padding: 13px 10px 0 0;

  .searchWrapper {
    margin-right: 12px;
  }
}

::v-deep .el-dialog {
  width: 373px;

  .el-input__inner {
    width: 329px;
    height: 38px;
  }

  .all {
    width: 36px;
    height: 12px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 18px;
    margin-left: 22px;
    margin-top: 9px;
    margin-right: 20px;
  }

  ::v-deep .el-radio {
    margin-right: 15px;
  }

  ::v-deep .el-radio__inner {
    width: 10px;
    height: 10px;
  }

  ::v-deep .el-radio__label {
    width: 24px;
    height: 12px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 18px;
  }

  ::v-deep .tagColor {
    margin-right: 17px;
    min-width: 56px;
    height: 14px;
    font-size: 12px !important;

    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
    float: left;
  }

  ::v-deep .el-checkbox__label {
    display: none;
  }
}

::v-deep .add_color {
  background: rgba(0, 109, 204, 1) !important;
  color: #fff;
}

.colors {
  margin-top: 20px;

  ::v-deep .el-radio__inner {
    width: 14px;
    height: 14px;
    border-radius: 0;
  }

  ::v-deep .el-radio__label {
    display: none;
  }

  ::v-deep .hong {
    .el-radio__inner {
      background: #f6817b;
      border: none;
    }
  }

  ::v-deep .huang {
    .el-radio__inner {
      background: #fec366;
      border: none;
    }
  }

  ::v-deep .hui {
    .el-radio__inner {
      background: #c8c6c6;
      border: none;
    }
  }

  ::v-deep .lan {
    .el-radio__inner {
      background: #62a5f3;
      border: none;
    }
  }

  ::v-deep .lv {
    .el-radio__inner {
      background: #7cedcc;
      border: none;
    }
  }
}

::v-deep .cell {
  text-align: center;
}
::v-deep .el-dialog__body {
  padding: 30px 20px !important;
}
::v-deep .tab_box .el-table::before {
  height: 0 !important;
}
</style>